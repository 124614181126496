import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import BroadcastBridge from "../plugins/Android/BroadcastBridge";
// services
import Constants from "./constants";
// types
import type { GatewayId, GatewayName, GatewayStatus } from "../types/gateway";

export const storeGatewayPreferences = async (gatewayId: GatewayId, gatewayName: GatewayName, gatewayStatus: GatewayStatus): Promise<void> => {
	await Preferences.set({ key: "selected_gateway_id", value: gatewayId });
	await Preferences.set({ key: "selected_gateway_name", value: gatewayName });
	await Preferences.set({ key: "selected_gateway_status", value: gatewayStatus });

	if (Capacitor.getPlatform() === Constants.Platform.Android) {
		await BroadcastBridge.sendAppWidgetUpdateBroadcast();
	}
};

export const clearGatewayPreferences = async (): Promise<void> => {
	await Preferences.remove({ key: "selected_gateway_id" });
	await Preferences.remove({ key: "selected_gateway_name" });
	await Preferences.remove({ key: "selected_gateway_status" });

	if (Capacitor.getPlatform() === Constants.Platform.Android) {
		await BroadcastBridge.sendAppWidgetUpdateBroadcast();
	}
};
