import i18n from "./i18n";
// types
import type { Timezone, Day, Language } from "../types/misc";

// Language depending

/**
 * @deprecated use `useLanguage` hook or `i18n.resolvedLanguage as Language` directly
 */
export const UI_LANGUAGE = i18n.resolvedLanguage as Language;

/**
 * @deprecated use `useTimezone` hook
 */
export const TIME_ZONE = new Intl.DateTimeFormat(UI_LANGUAGE).resolvedOptions().timeZone as Timezone;

const weekdayFormatter = new Intl.DateTimeFormat(UI_LANGUAGE, { weekday: "short" }).format;
/**
 * @deprecated use `useWeekdayFormatter` hook
 */
export const getWeekDayL10n = (dayOfWeek: Day) => {
	const dayInYearMonthStartsWithMonday = new Date(2018, 0, dayOfWeek % 7); // 2018-01-01 = Monday
	return weekdayFormatter(dayInYearMonthStartsWithMonday);
};

// Format depending

/**
 * @deprecated use `useLocale` hook
 */
export const LOCALE = new Intl.Locale(new Intl.NumberFormat().resolvedOptions().locale);

/**
 * @deprecated use `useNumberFormatter` hook
 */
export const getNumberFormatter = (fractionDigits: number): Intl.NumberFormat["format"] => (
	new Intl.NumberFormat(LOCALE.language, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }).format
);

/**
 * @deprecated use `useNumberFormatter` hook
 */
export const numberFormatter0Digits = getNumberFormatter(0);
/**
 * @deprecated use `useNumberFormatter` hook
 */
export const numberFormatter1Digits = getNumberFormatter(1);

/**
 * @deprecated use `useSorter` hook
 */
export const sortAlphabetically = new Intl.Collator(LOCALE.baseName, { sensitivity: "base" }).compare;
