import { EventEmitter } from "events";
import { CapacitorException, ExceptionCode } from "@capacitor/core";
import { LocalNotifications } from "@capacitor/local-notifications";
// services
import Glient from "./glient";
import User from "./user";
import Gateways from "./gateways";
import Gateway from "./gateway";
import Constants from "./constants";
// types
import type { ReadonlyDeep } from "type-fest";
import type {
	CmdGetAttributeReports, CmdGetAttributeReportsFilter, CmdRetireReport,
	MsgBroadcastAttributeReport, MsgBroadcastRetireReports, MsgResponseGetAttributeReports,
	PayloadResponseGetAttributeReportsDataResponse, PayloadBroadcastAttributeReport,
	PayloadBroadcastAttributeReportAlertRule, // PayloadBroadcastAttributeReportAlertGateway,
} from "../types/message";
import type { GatewayId } from "../types/gateway";
import type { _MsgResponseGetAttributeReports, HistoryType, HistoryTags, Response, AdditionFilter, IsAvailableCallback } from "../types/history";
import type { EventId } from "../types/misc";

/**
 * An in-memory storage for history.
 * Note: Does not store the history items but store if there is any record available or not.
 * @class History
 * @extends EventEmitter
 */
class History extends EventEmitter {

	#available = false;
	#availableFetched = false;
	#unreadAlertIds: Array<EventId> = [];
	#unreadAlertsCount = 0;
	#gatewayId: GatewayId | null = null;

	#response: Response = {
		event: {
			error: null,
			msg: undefined,
		},
		rule: {
			error: null,
			msg: undefined,
		},
		alert: {
			error: null,
			msg: undefined,
		},
		report: {
			error: null,
			msg: undefined,
		},
		all: {
			error: null,
			msg: undefined,
		},
		device: {
			error: null,
			msg: undefined,
		},
	};

	#additionFilter: AdditionFilter = {};

	public getAttributeReportsCmd(start: number, rows: number, gatewayIds: Array<GatewayId>, filter: Partial<CmdGetAttributeReportsFilter> = {}): CmdGetAttributeReports {
		return {
			action: "getAttributeReports",
			sort: "_gagent_ts desc",
			start: `${start}`,
			rows: `${rows}`,
			filter: {
				tags: [Constants.HistoryTags.Event, Constants.HistoryTags.Rule, Constants.HistoryTags.Alert],
				gagentTSMin: "*",
				gagentTSMax: "*",
				gatewayId: gatewayIds,
				...filter,
			},
		};
	}

	public getAttributeReports(type: HistoryType, start: number = 0): () => void {
		const gatewayIds = Gateways.getGatewayIdsOffVisibleDevices();
		const cmd = this.getAttributeReportsCmd(start, 50, gatewayIds, {
			tags: this.getHistoryTagsByHistoryType(type),
			...this.getAdditionFilter(type),
		});
		const handlerId = Glient.send(cmd, (error, msg) => {
			this.setHistoryReportsResponse(type, error, msg);
		});
		return () => (Glient.abort(handlerId));
	}

	public handleAttributeReportBroadcast(msg: MsgBroadcastAttributeReport): void {
		if (Array.isArray(msg.payload.tags) && msg.payload.tags.length > 0) {
			this.emit("attributeReport", msg);

			if (msg.payload.status === "ok" && msg.payload.tags.includes(Constants.HistoryTags.Alert) && !this.#unreadAlertIds.includes(msg.payload.id)) {
				this.#unreadAlertIds.push(msg.payload.id);
				this.#unreadAlertsCount += 1;
				this.emit("unreadAlertsCountChanged");
			}

			const HISTORY_TYPES = [...Object.values(Constants.Tabs.History), "device" satisfies HistoryType] as const;
			HISTORY_TYPES.filter((type) => (
				this.isHistoryDataLoaded(type) && this.#filterAttributeReportByAdditionFilter(type, msg) && this.getHistoryTagsByHistoryType(type).some((tag) => (msg.payload.tags.includes(tag)))
			)).forEach((type) => {
				this.#updateAttributeReport(type, msg);
				this.emit("attributeReportsChanged", type, this.#response[type].msg!.payload.data.response!.docs);
			});
		}
	}

	public async handleAttributeReportBroadcastAllGateways(msg: MsgBroadcastAttributeReport): Promise<void> {
		if (msg.payload.status === "ok" && Array.isArray(msg.payload.tags) && msg.payload.tags.includes(Constants.HistoryTags.Alert) && !this.#unreadAlertIds.includes(msg.payload.id)) {
			try {
				const { display } = await LocalNotifications.checkPermissions();
				if (display === "granted") {
					const { gatewayId, id: alertId, alertMsg } = msg.payload as PayloadBroadcastAttributeReportAlertRule; // TODO check: | PayloadBroadcastAttributeReportAlertGateway;
					const gateway = Gateways.getGateways().find((gateway) => (gateway.id === gatewayId));
					if (gateway) {
						await LocalNotifications.schedule({
							notifications: [{
								id: Math.round(Date.now() / 1000), //notification.id,
								title: gateway.name ?? "",
								body: alertMsg,
								extra: {
									gId: gatewayId,
									aId: alertId,
								},
							}],
						});
					}
				}
			} catch (error) {
				if (error instanceof CapacitorException && error.code === ExceptionCode.Unavailable) {
					console.info(error.message, error);
				}
			}
		}
	}

	#filterAttributeReportByAdditionFilter(type: HistoryType, msg: MsgBroadcastAttributeReport): boolean {
		const additionFilter = this.getAdditionFilter(type);
		if (Array.isArray(additionFilter.deviceId) && !additionFilter.deviceId.includes(msg.payload.deviceId)) {
			return false;
		}
		if (Array.isArray(additionFilter.cluster_id) && !additionFilter.cluster_id.includes(msg.payload.cluster_id)) {
			return false;
		}
		if (Array.isArray(additionFilter["-cluster_id"]) && additionFilter["-cluster_id"].includes(msg.payload.cluster_id)) {
			return false;
		}
		return true;
	}

	#updateAttributeReport(type: HistoryType, msg: MsgBroadcastAttributeReport): void {
		if (Array.isArray(msg.payload.tags)) { // TODO: remove check
			if (this.isHistoryDataLoaded(type)) {
				const attributeReports = this.#response[type].msg!.payload.data.response!.docs;
				const index = attributeReports.findIndex((attribute) => (attribute.id === msg.payload.id));
				const gatewayIds = Gateways.getGatewayIdsOffVisibleDevices();
				if (gatewayIds.includes(msg.payload.gatewayId)) {
					if (index === -1) {
						attributeReports.unshift(msg.payload as PayloadBroadcastAttributeReport);
					} else {
						attributeReports[index] = msg.payload;
					}
				}
			}
		}
	}

	public getAdditionFilter(type: HistoryType): AdditionFilter {
		switch (type) {
			case Constants.Tabs.History.Event:
				return { ...this.#additionFilter, "-cluster_id": User.channelInfo?.reportClusterIds };
			case Constants.Tabs.History.Report:
				return { ...this.#additionFilter, cluster_id: User.channelInfo?.reportClusterIds };
			default:
				return this.#additionFilter;
		}
	}

	public setAdditionFilter(additionFilter: AdditionFilter): void {
		this.#additionFilter = additionFilter;
		this.emit("additionFilterChanged");
	}

	public clearAdditionFilter(): void {
		this.#additionFilter = {};
		// this.clearAllAttributeReports();
	}

	public getHistoryTagsByHistoryType(type: HistoryType): HistoryTags {
		switch (type) {
			case Constants.Tabs.History.All:
				return User.channelInfo?.separateAlerts ? [Constants.HistoryTags.Event, Constants.HistoryTags.Rule] : [Constants.HistoryTags.Event, Constants.HistoryTags.Rule, Constants.HistoryTags.Alert];
			case Constants.Tabs.History.Event:
				return [Constants.HistoryTags.Event];
			case Constants.Tabs.History.Rule:
				return [Constants.HistoryTags.Rule];
			case Constants.Tabs.History.Alert:
				return [Constants.HistoryTags.Alert];
			case Constants.Tabs.History.Report:
				return [Constants.HistoryTags.Event];
			case "device":
				return [Constants.HistoryTags.Event];
			default:
				return [];
		}
	}

	public clearAttributeReport(type: HistoryType): void {
		this.#response[type].error = null;
		this.#response[type].msg = undefined;
	}

	public clearAllAttributeReports(): void {
		for (const type of Object.keys(this.#response)) {
			this.clearAttributeReport(type as HistoryType);
		}
	}

	/**
	 * Calls the history API with rows as 100 and save the ids to check for alert updates.
	 * @param {Function} callback
	 */
	public isAvailable(callback: IsAvailableCallback): () => void {
		const selectedGatewayId = Gateway.selectedGatewayId;
		if (selectedGatewayId === undefined) {
			callback(false);
			return () => {};
		} else if (this.#gatewayId === selectedGatewayId && this.#availableFetched) {
			callback(this.#available);
			return () => {};
		} else {
			const gatewayIds = Gateways.getGatewayIdsOffVisibleDevices();
			const cmd = this.getAttributeReportsCmd(0, 100, gatewayIds);
			const handlerId = Glient.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					const docs = msg.payload.data.response?.docs ?? [];

					this.#available = docs.length > 0;
					this.#availableFetched = true;
					this.#unreadAlertIds = docs.filter((alert) => (alert.status === "ok")).map((alert) => (alert.id));
					this.#unreadAlertsCount = msg.payload.aggs?.tags.alert?.ok ?? 0;
					this.#gatewayId = selectedGatewayId;
				}
				this.setHistoryReportsResponse(Constants.Tabs.History.All, error, msg);
				callback(this.#available);
			});
			return () => (Glient.abort(handlerId));
		}
	}

	public isHistoryDataLoaded(type: HistoryType): boolean {
		return Array.isArray(this.#response[type].msg?.payload.data.response?.docs);
	}

	public getHistoryDataResponse(type: HistoryType): ReadonlyDeep<PayloadResponseGetAttributeReportsDataResponse> {
		return this.#response[type].msg!.payload.data.response!;
	}

	public getUnreadAlertsCount(): number {
		return this.#unreadAlertsCount;
	}

	public getHistoryEntryById(type: HistoryType, eventId: EventId): ReadonlyDeep<PayloadBroadcastAttributeReport> | undefined {
		return this.#response[type].msg?.payload.data.response!.docs.find((doc) => (doc.id === eventId));
	}

	public setHistoryReportsResponse(type: HistoryType, error: Error | null, msg: MsgResponseGetAttributeReports | undefined): void {
		this.#response[type].error = error;
		if (!error && msg?.payload.status === "ok") {
			const _msg = structuredClone(msg) as _MsgResponseGetAttributeReports;
			if (_msg.payload.data.response === undefined) {
				_msg.payload.data.response = {
					docs: [],
					numFound: 0,
					start: "",
				};
			}
			// TODO: remove invalid history entries without "_childDocuments_"
			_msg.payload.data.response.docs = _msg.payload.data.response.docs.filter((doc) => (doc._childDocuments_ !== undefined));
			if (this.isHistoryDataLoaded(type)) {
				const docs = [...this.#response[type].msg!.payload.data.response!.docs, ..._msg.payload.data.response.docs];
				this.#response[type].msg = _msg;
				this.#response[type].msg.payload.data.response!.docs = docs;
			} else {
				this.#response[type].msg = _msg;
			}

			this.emit("attributeReportsLoaded", type);
		}
	}

	public handleRetireReportsBroadcast(msg: MsgBroadcastRetireReports): void {
		if (User.channelInfo?.features.alerts?.showUnreadAlerts && msg.payload.status === "ok") {
			const HISTORY_TYPES = [Constants.Tabs.History.All, Constants.Tabs.History.Alert, "device" satisfies HistoryType] as const;
			for (const type of HISTORY_TYPES) {
				if (this.isHistoryDataLoaded(type)) {
					for (const docId of msg.payload.data) {
						const attributeReport = this.#response[type].msg!.payload.data.response!.docs.find((doc) => (doc.id === docId));
						if (attributeReport) {
							attributeReport.status = "retired";
						}
					}
					this.emit("attributeReportsChanged", type, this.#response[type].msg!.payload.data.response!.docs);
				}
			}

			this.#unreadAlertIds = this.#unreadAlertIds.filter((unreadAlertId) => (!msg.payload.data.includes(unreadAlertId)));
			this.#unreadAlertsCount = Math.max(0, this.#unreadAlertsCount - msg.payload.data.length);
			this.emit("unreadAlertsCountChanged");
		}
	}

	public retireAlert(gatewayId: GatewayId, eventId: EventId): void {
		if (User.channelInfo?.features.alerts?.showUnreadAlerts) {
			const cmd = {
				action: "retireReport",
				gatewayId: gatewayId,
				reportIds: [eventId],
			} as const satisfies CmdRetireReport;
			Glient.send(cmd);
		}
	}

	public retireAllAlerts(): void {
		const cmd = {
			action: "retireReport",
			gatewayId: Gateway.selectedGatewayId!,
			tags: [Constants.HistoryTags.Alert],
		} as const satisfies CmdRetireReport;
		Glient.send(cmd);
	}

}

export default (new History());
